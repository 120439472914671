import request from '@/utils/request'

const backAccountApi = {
  backAccount_list: '/corporation_bank_account/',
  backAccount_create: 'corporation_bank_account/',
  backAccount_delete: '/corporation_bank_account/'
}

/**
 * 列表
 */
export function backAccount_list (parameter) {
  return request({
    url: backAccountApi.backAccount_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function backAccount_create (parameter) {
  return request({
    url: backAccountApi.backAccount_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function backAccount_delete (backAccount_id) {
  return request({
    url: backAccountApi.backAccount_delete + backAccount_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
