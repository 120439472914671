<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="户名">
              <a-input v-decorator="['account_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="账号">
              <a-input v-decorator="['account', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { corporation_list } from '@/api/corporation'
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      groupData: [],
      dataSource: []
    }
  },
  created () {
    this.groupList(undefined)
  },
  methods: {
    groupList (obj) {
      // group_list(obj)
      //   .then(res => {
      //     this.groupData = res.data.entries
      //   })
    },
    handleGroupChange (value) {
      console.log(value)
    },
    groupSearch (value) {
      console.log(value)
      this.groupList({ number: value })
    },
    // 公司
    corporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        // this.dataSource = !value ? [] : result
        this.dataSource = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    corporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handlecorporationSearch(value)
      }
    },
    propertyOwnerChange (value) {
      console.log(value)
    }
  }
}
</script>
